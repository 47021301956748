import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
} from "@angular/animations";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Title } from "@angular/platform-browser";

declare var App: any;

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],

  animations: [
    trigger("pageLoadAnimate", [
      transition(":enter", [
        query("*", [
          style({ transform: "translateX(-200px)", opacity: 0 }),
          stagger(10, [
            animate(
              "500ms cubic-bezier(0.35, 1, 0.25, 1)",
              style({ transform: "translateX(0)", opacity: 1 })
            ),
          ]),
        ]),
      ]),
    ]),
  ],

  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
  public headerLogo: string =
    App.public_url + "login/assets/images/Login_logo.svg";
  public email: string = "";
  public invalidEmail: boolean = false;
  public emailErrorText: string = "Please Enter Valid Email";
  emailSent: boolean = false;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Expodite Log-in");
  }

  validateEmail() {
    if (!this.isValidEmail(this.email)) {
      this.invalidEmail = true;
      return;
    }

    this.http
      .post(App.base_url + "forgotPassword", { email: this.email })
      .toPromise()
      .then((response: any) => {
        if (response.result.success) {
          this.emailSent = true;
        } else {
          this.invalidEmail = true;
          this.emailErrorText = response.result.message;
        }
      })
      .catch(
        (error) => console.log("object")
        // this.server_error = "Invalid username and password"
      );
  }

  isValidEmail(email: string, type?: string): boolean {
    if (type === "input" && !this.invalidEmail) return;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) this.invalidEmail = true;
    else this.invalidEmail = false;
    return emailRegex.test(email);
  }
}
