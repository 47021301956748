import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
// import { HttpProviderService } from "./services/http-provider.service";

// import { CustomMaterialModule } from "./custom-material/custom-material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { MatCardModule } from "@angular/material/card";

// import { LoginService } from "./services/login.service";
import { SnakbarService } from "./snackbar.service";
import { SnakbarComponent } from "./snackbar/snackbar.component";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { SharedModule } from "./shared/shared.module";
import { RouterModule } from "@angular/router";
import { VerifyOtpComponent } from "./verify-otp/verify-otp.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
// import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
// import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
// import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

// import { AppRoutingModule } from "./app-routing/app-routing.module";
// import { CreatePasswordComponent } from "./login/create-password/create-password.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // ForgotPasswordComponent,
    // ResetPasswordComponent,
    // PageNotFoundComponent,
    SnakbarComponent,
    // CreatePasswordComponent,
    VerifyOtpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SpinnerComponent,
  ],
  imports: [
    MatSnackBarModule,
    MatFormFieldModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatProgressSpinnerModule,

    SharedModule,
    RouterModule.forRoot([
      {
        path: "do-login",
        component: LoginComponent,
      },
      {
        path: "verifyotp",
        component: VerifyOtpComponent,
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
      },
      { path: "reset-password/:id/:token", component: ResetPasswordComponent },
      {
        path: "",
        redirectTo: "/do-login",
        pathMatch: "full",
      },
    ]),
    // CustomMaterialModule,
    // AppRoutingModule,
  ],
  providers: [
    Title,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   // useClass: HttpProviderService,
    //   multi: true,
    // },
    // LoginService,
    SnakbarService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private titleService: Title) {
    this.titleService.setTitle("Expodite");
  }
}
