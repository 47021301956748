import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
} from "@angular/animations";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Params } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

declare var App: any;

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  animations: [
    trigger("pageLoadAnimate", [
      transition(":enter", [
        query("*", [
          style({ transform: "translateX(-200px)", opacity: 0 }),
          stagger(10, [
            animate("500ms cubic-bezier(0.35, 1, 0.25, 1)", style("*")),
          ]),
        ]),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit {
  public headerLogo: string =
    App.public_url + "login/assets/images/Login_logo.svg";
  public newPassword: string = "";
  public confirmPassword: string = "";
  public noMatch = false;
  cratepassword: any;
  urlParams: object;
  private router: Router;
  error: any;

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Expodite - Reset Password");

    this.activeRoute.params.subscribe((params: Params) => {
      this.cratepassword = params["create"];
      this.urlParams = params;
    });
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    console.log(this.urlParams);
    console.log(this.cratepassword);
  }

  submitForm() {
    if (this.newPassword !== this.confirmPassword) {
      this.noMatch = true;
      return;
    }

    this.http
      .post(App.base_url + "resetPasswordLink", {
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        ...this.urlParams,
      })
      .toPromise()
      .then((response: any) => {
        if (response.result.success) {
          this.router.navigate(["/do-login"]);
        } else {
          this.error = response.result.message;
          return;
        }
      });

    // Reset password logic goes here
  }

  validateMatch() {
    if (this.noMatch) {
      if (this.newPassword == this.confirmPassword) this.noMatch = false;
      else this.noMatch = true;
    }
  }
}
