import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
} from "@angular/animations";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { SnakbarService } from "../snackbar.service";
declare var App: any;

@Component({
  selector: "app-verify-otp",
  templateUrl: "./verify-otp.component.html",
  styleUrls: ["./verify-otp.component.scss"],
  animations: [
    trigger("pageLoadAnimate", [
      transition(":enter", [
        query("*", [
          style({ transform: "translateX(-200px)", opacity: 0 }),
          stagger(10, [
            animate("500ms cubic-bezier(0.35, 1, 0.25, 1)", style("*")),
          ]),
        ]),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class VerifyOtpComponent implements OnInit {
  @ViewChild("otp1Input") otp1Input: ElementRef;
  verifyOTP: FormGroup;
  public givenOtp: string = "";
  public headerLogo: string =
    App.public_url + "login/assets/images/Login_logo.svg";
  invalidOpt: boolean = false;
  error: any;
  message: any;
  resendLoad: boolean = false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private snackbar: SnakbarService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Expodite - Verify OTP");

    this.verifyOTP = this.formBuilder.group({
      otp1: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      otp2: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      otp3: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      otp4: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      otp5: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      otp6: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
    });

    this.startOTPTimer();
  }

  ngAfterViewInit(): void {
    this.focusOnInput(this.otp1Input);
  }

  areAllFieldsFilled() {
    console.log(Object.values(this.verifyOTP.controls));
    return Object.values(this.verifyOTP.controls).every(
      (control) =>
        control.value !== null &&
        control.value !== undefined &&
        control.value !== ""
    );
  }

  validateOTP() {
    if (!this.areAllFieldsFilled()) {
      return;
    }
    this.invalidOpt = true;

    this.givenOtp = "";
    this.givenOtp = Object.values(this.verifyOTP.controls).reduce(
      (otp, control) => otp + control.value,
      ""
    );

    this.http
      .post(App.base_url + "validateOtp", { two_factor_code: this.givenOtp })
      .toPromise()
      .then((response: any) => {
        if (response.result.success) {
          window.location.href = App.base_url;
          // localStorage.setItem("loadTime", new Date().getTime().toString());
        } else {
          this.verifyOTP.reset();
          this.focusOnInput(this.otp1Input);
          this.invalidOpt = false;
          this.error = response.result.message;
          this.message = null;

          return;
        }
      });
  }

  resentOtp() {
    this.resendLoad = true;
    this.http
      .post(App.base_url + "resendOtp", {})
      .toPromise()
      .then((response: any) => {
        if (response.result.success) {
          this.verifyOTP.reset();
          this.focusOnInput(this.otp1Input);
          let toast: object;
          this.startOTPTimer();
          this.message = response.result.message;
          this.error = null;
        } else {
          this.verifyOTP.reset();
          this.focusOnInput(this.otp1Input);
          this.error = response.result.message;
          this.message = null;
          this.resendLoad = false;
        }
      });
  }

  focusNextInput(event: any, nextInput: HTMLInputElement, controlerName): void {
    event.target.value = event.target.value.charAt(
      event.target.value.length - 1
    );
    const control = this.verifyOTP.get(controlerName);
    if (control) {
      control.setValue(event.target.value);
    }
    if (event.target.value && nextInput) {
      nextInput.focus();
    }
  }

  focusPreviousInput(event: any, previousInput: HTMLInputElement): void {
    if (!event.target.value && previousInput) {
      previousInput.focus();
    }
  }

  focusOnInput(inputElement: ElementRef): void {
    if (inputElement) {
      inputElement.nativeElement.focus();
    }
  }

  resendInterval = 60; // Resend interval in seconds
  secondsLeft: number;
  otpTimer: any;

  startOTPTimer() {
    this.secondsLeft = this.resendInterval;
    this.otpTimer = setInterval(() => {
      this.secondsLeft--;
      if (this.secondsLeft === 0) {
        clearInterval(this.otpTimer);
      }
    }, 1000);
    this.resendLoad = false;
  }
}
