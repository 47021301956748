<div class="login-container">
  <div class="logo-container">
    <img [src]="headerLogo" alt="Logo" />
  </div>
  <form
    #loginForm="ngForm"
    (ngSubmit)="login(loginForm.form)"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="body-container">
      <p *ngIf="server_error" class="error-msg error-msg-label">
        {{ server_error }}
      </p>
      <div class="email-container">
        <input
          email
          name="username"
          required
          [(ngModel)]="loginDetails.username"
          type="text"
          placeholder="Email Address"
        />
        <p *ngIf="!isValidEmail(loginDetails.username)" class="error-msg">
          Please Enter Valid Email
        </p>
      </div>
      <div class="password-container">
        <input
          name="password"
          [(ngModel)]="loginDetails.password"
          required
          type="password"
          placeholder="Password"
        />
        <p *ngIf="loginDetails.password === ''" class="error-msg">
          Please Enter Valid Password
        </p>
      </div>

      <div *ngIf="is_captcha_enabled" class="captcha-container">
        <div class="captcha">
          <span>captcha: </span>
          <img [src]="captcha_src" id="captcha" />
          <button (click)="getCaptcha()" type="button">
            <i class="material-icons">autorenew</i>
          </button>
        </div>
        <div>
          <!-- <mat-form-field class="custom-input" float Placeholder="never"> -->
          <!--  #captcha="ngModel" -->
          <input
            [(ngModel)]="loginDetails.captcha"
            matInput
            name="captcha"
            type="text"
            required
          />

          <div
            *ngIf="submit && captcha.errors.required"
            class="alert-error error mat-error"
          >
            Please Enter Captcha
          </div>
        </div>
      </div>
      <div class="flex-container">
        <div class="remember-container">
          <input
            [(ngModel)]="loginDetails.remember"
            name="remember"
            type="checkbox"
          />Remember me
        </div>
        <div class="forgot-container">
          <button [routerLink]="['/forgot-password']">forgot password?</button>
        </div>
      </div>
      <div class="button-container">
        <button
          [ngClass]="{
            'disabled-button ': submitDisabled || loginForm.invalid
          }"
          [disabled]="loginForm.invalid || submitDisabled"
          type="submit"
        >
          <ng-container *ngIf="submitDisabled; else signInText">
            <app-spinner></app-spinner>
          </ng-container>
          <ng-template #signInText> SIGN IN </ng-template>
        </button>
      </div>
    </div>
  </form>
</div>
