<div class="login-container">
  <div class="logo-container">
    <img [src]="headerLogo" alt="Logo" />
  </div>
  <!-- #loginForm="ngForm" (ngSubmit)="login(loginForm.form)" -->
  <form (ngSubmit)="submitForm()" #resetForm="ngForm">
    <div class="body-container">
      <h3 class="label">Reset Password</h3>
      <p *ngIf="error" class="error-msg error-msg-label">{{ error }}</p>
      <div class="password-container">
        <input
          required
          type="password"
          placeholder="New Password"
          name="newPassword"
          [(ngModel)]="newPassword"
          #newPasswordInput="ngModel"
        />
        <div
          *ngIf="
            newPasswordInput.invalid &&
            (newPasswordInput.dirty || newPasswordInput.touched)
          "
          class="error-msg"
        >
          Please enter a valid password.
        </div>
      </div>
      <div class="password-container">
        <input
          required
          type="password"
          placeholder="Confirm New Password"
          name="confirmPassword"
          [(ngModel)]="confirmPassword"
          #confirmPasswordInput="ngModel"
          (input)="validateMatch()"
        />
        <div
          *ngIf="
            confirmPasswordInput.invalid &&
            !noMatch &&
            (confirmPasswordInput.dirty || confirmPasswordInput.touched)
          "
          class="error-msg"
        >
          Passwords do not match. Please try again.
        </div>
        <div *ngIf="noMatch" class="error-msg">
          Passwords do not match. Please try again.
        </div>
      </div>

      <div class="button-container">
        <button
          type="submit"
          [ngClass]="{ 'disabled-button ': resetForm.invalid || noMatch }"
          [disabled]="resetForm.invalid"
        >
          SUBMIT
        </button>
      </div>
    </div>
  </form>
</div>
