<div class="login-container">
  <div class="logo-container">
    <img [src]="headerLogo" alt="Logo" />
  </div>
  <!-- <form #loginForm="ngForm" (ngSubmit)="login(loginForm.form)"> -->
  <div *ngIf="emailSent" class="body-container">
    <div class="alert-popup-container">
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
      <div class="alert-popup-title">Mail Sent Successfully!</div>
    </div>
    <p class="link">
      <i class="material-icons">keyboard_backspace</i>
      <button [routerLink]="['/do-login']">Back to login</button>
      <!-- (click)="resentOtp()"  -->
    </p>
  </div>

  <div *ngIf="!emailSent" class="body-container">
    <h3 class="label">Enter the email address associated with your account.</h3>
    <!-- <p class="error-msg error-msg-label">Invalid email</p> -->
    <div class="email-container">
      <input
        [(ngModel)]="email"
        [ngClass]="{ 'invalid-email': invalidEmail }"
        type="email"
        placeholder="Email Address"
        (blur)="isValidEmail(email)"
        (input)="isValidEmail(email, 'input')"
      />
      <p class="error-msg" *ngIf="invalidEmail">{{ emailErrorText }}</p>
    </div>
    <div class="button-container">
      <button
        (click)="validateEmail()"
        [ngClass]="{ 'disabled-button ': invalidEmail || email === '' }"
        [disabled]="invalidEmail"
      >
        SUBMIT
      </button>
    </div>
    <p class="link">
      <i class="material-icons">keyboard_backspace</i>
      <button [routerLink]="['/do-login']">Back to login</button>
      <!-- (click)="resentOtp()"  -->
    </p>
  </div>
  <!-- </form> -->
</div>
