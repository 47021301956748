import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { SnakbarComponent } from "./snackbar/snackbar.component";

@Injectable({
  providedIn: "root",
})
export class SnakbarService {
  message: string = "Snack Bar opened.";
  actionButtonLabel: string = "Retry";
  action: boolean = false;
  setAutoHide: boolean = true;
  autoHide: number = 4000;
  addExtraClass: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(private snackBar: MatSnackBar) {}

  showSnackBar(data: any): void {
    let config = new MatSnackBarConfig();

    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = this.addExtraClass ? ["party"] : ["default"];
    config.data = data;
    this.snackBar.openFromComponent(SnakbarComponent, config);
  }
}
