import { FileUploadModule } from "ng2-file-upload";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
// import { EpiCurrencyPipe } from './../pipes/epi-currency.pipe';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ReactiveFormsModule } from "@angular/forms";
import { QuillModule } from "ngx-quill";
import "quill-mention";
import "quill-emoji";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ScrollingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AngularEditorModule,

    FlexLayoutModule,

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    FileUploadModule,
    NgSelectModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,

    // ExportDialogComponent,
    // MoreEmailsComponent
  ],
  providers: [
    // EpiCurrencyPipe,
    // MyFilterPipe,
    // LetterPipe,
    // DatePipe,
    // PowerConversionPipe, LetterPipe, ContactImageNamePipe,
  ],
  exports: [
    CommonModule,
    ScrollingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AngularEditorModule,
    FlexLayoutModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    FileUploadModule,
    NgSelectModule,
    QuillModule,
  ],
  entryComponents: [
    // ExportDialogComponent,
    // MoreEmailsComponent
  ],
})
export class SharedModule {}
