import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
} from "@angular/animations";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Title } from "@angular/platform-browser";

declare var App: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [Title, CookieService],

  animations: [
    trigger("pageLoadAnimate", [
      transition(":enter", [
        query("*", [
          style({ transform: "translateX(-200px)", opacity: 0 }),
          stagger(10, [
            animate(
              "500ms cubic-bezier(0.35, 1, 0.25, 1)",
              style({ transform: "translateX(0)", opacity: 1 })
            ),
          ]),
        ]),
      ]),
    ]),
  ],

  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  @ViewChild("myInput") inputEl: ElementRef;
  loginForm: FormGroup;
  public is_captcha_enabled: boolean = false;
  public captcha_src: string =
    "http://pvm.local-expodite.co.in//captcha/flat?0.10842043746092878";

  server_error: string;

  public headerLogo: string =
    App.public_url + "login/assets/images/Login_logo.svg";
  public headerLogo1: string =
    App.public_url + "login/assets/images/ksm-662.png";
  submit: boolean;

  loginDetails: any = {};
  submitDisabled: boolean = false;

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private router: Router,
    private titleService: Title,

    // private loginService: LoginService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.checkCookie();
    this.titleService.setTitle("Expodite Log-in");

    // this.inputEl.nativeElement.focus();
    // this.createLoginForm();
  }

  checkCookie(): void {
    if (this.cookie.check("username") && this.cookie.check("password")) {
      this.loginDetails.username = this.cookie.get("username");
      this.loginDetails.password = this.cookie.get("password");
      this.loginDetails.remember = true;
    }
  }

  createLoginForm(): void {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      captcha: [null, Validators.required],
      rememberMe: "",
    });
  }

  login(form: any): void {
    this.submit = true;
    if (!form.valid) return;
    this.submit = false;
    this.submitDisabled = true;
    this.http
      .post(App.base_url + "getLogin", this.loginDetails)
      .toPromise()
      .then((response: any) => {
        if (!response.result.status) {
          this.is_captcha_enabled = response.result.enable_captcha;
          if (this.is_captcha_enabled) {
            this.getCaptcha();
          }
          // this.server_error = response.result.message;
        }
        if (response.result.data.status) {
          if (this.loginDetails.remember) {
            this.cookie.set("username", this.loginDetails.username);
            this.cookie.set("password", this.loginDetails.password);
          } else {
            this.cookie.delete("username");
            this.cookie.delete("password");
          }
          // this.server_error = "";
          this.goToDashboard();
        } else {
          this.is_captcha_enabled = response.result.enable_captcha;
          this.server_error = response.result.message;
          this.submitDisabled = false;
        }
      })
      .catch(
        (error) => console.log("object")
        // this.server_error = "Invalid username and password"
      );
  }

  getCaptcha() {
    this.captcha_src = "";
    this.captcha_src = App.base_url + "/captcha/flat?" + Math.random();
    console.log("Captcha URL:", this.captcha_src);
  }

  goToDashboard(): void {
    if (App.two_factor) {
      this.router.navigate(["/verifyotp"]);
    } else {
      window.location.href = App.base_url;
      // localStorage.setItem("loadTime", new Date().getTime().toString());
    }
  }

  isValidEmail(email: string): boolean {
    if (!email || email.trim() === "") {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
