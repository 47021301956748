<div class="login-container">
  <div class="logo-container">
    <img [src]="headerLogo" alt="Logo" />
  </div>
  <!-- <form #loginForm="ngForm" (ngSubmit)="login(loginForm.form)"> -->
  <div class="body-container">
    <div>
      <h3 class="label">Enter OTP</h3>
      <p *ngIf="error" class="error-msg error-msg-label">{{ error }}</p>
      <p *ngIf="message" class="message-msg error-msg-label">{{ message }}!</p>

      <div class="otp-field" [formGroup]="verifyOTP">
        <input
          #otp1Input
          type="number"
          maxlength="1"
          formControlName="otp1"
          (input)="focusNextInput($event, otp2Input, 'otp1')"
          (keydown.backspace)="focusPreviousInput($event, null)"
        />
        <input
          #otp2Input
          type="number"
          maxlength="1"
          formControlName="otp2"
          (input)="focusNextInput($event, otp3Input, 'otp2')"
          (keydown.backspace)="focusPreviousInput($event, otp1Input)"
        />
        <input
          #otp3Input
          class="space"
          type="number"
          maxlength="1"
          formControlName="otp3"
          (input)="focusNextInput($event, otp4Input, 'otp3')"
          (keydown.backspace)="focusPreviousInput($event, otp2Input)"
        />
        <input
          #otp4Input
          type="number"
          maxlength="1"
          formControlName="otp4"
          (input)="focusNextInput($event, otp5Input, 'otp4')"
          (keydown.backspace)="focusPreviousInput($event, otp3Input)"
        />
        <input
          #otp5Input
          type="number"
          maxlength="1"
          formControlName="otp5"
          (input)="focusNextInput($event, otp6Input, 'otp5')"
          (keydown.backspace)="focusPreviousInput($event, otp4Input)"
        />
        <input
          #otp6Input
          type="number"
          maxlength="1"
          formControlName="otp6"
          (input)="focusNextInput($event, null, 'otp6')"
          (keydown.backspace)="focusPreviousInput($event, otp5Input)"
        />
      </div>
      <p *ngIf="!verifyOTP.valid" class="error-msg">Please enter valid OTP</p>
      <p class="link">
        Didn't get code?
        <button
          [ngClass]="{ 'disabled-button ': resendLoad }"
          *ngIf="secondsLeft <= 0"
          [disabled]="secondsLeft > 0"
          (click)="resentOtp()"
        >
          Resend OTP
        </button>
        <!-- <mat-spinner class="toast-spinner"></mat-spinner> -->

        <span *ngIf="secondsLeft > 0" style="margin-left: 10px"
          >Resend in {{ secondsLeft }} seconds</span
        >
      </p>
    </div>
    <div class="button-container">
      <button
        (click)="validateOTP()"
        [ngClass]="{ 'disabled-button ': !verifyOTP.valid || invalidOpt }"
        [disabled]="!verifyOTP.valid"
      >
        Submit
      </button>
    </div>
  </div>
  <!-- </form> -->
</div>
